import React, { Suspense, useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Box, Flex } from "./ui/layouts";
import { H1, BigButton } from "./ui/atoms";
import logo from "./assets/logo.png";
import Model from "./Model";
import { Canvas, useFrame } from "react-three-fiber";
import * as typeformEmbed from "@typeform/embed";
import screenshot from "./assets/screenshot.jpg";
import screenshot2 from "./assets/screenshot2.png";
import globe from "./assets/globe.png";
import reach from "./assets/reach.png";
import star from "./assets/starpin.png";
import cardStar from "./assets/cardstar.png";
import strolling from "./assets/strolling.png";
import treeguy from "./assets/treeguy.png";
import explainer from "./assets/offset-explainer.png";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  "pk.eyJ1IjoibHVuYXJtYXlvciIsImEiOiJja2tvZDNlZnkwYnoyMm90b3l6amxzNHE2In0.OQ6KD5mcV79nFnevmkX91Q";

let appStoreLink = "https://apps.apple.com/us/app/yogo-life/id1553708066";

const Text = styled.p`
  color: white;
  font-size: 20px;
  line-height: 1.5;
  max-width: 600px;
  font-family: Helvetica Neue;
`;

const NavLink = styled.a`
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  margin: 0;
  text-decoration: none;
  font-family: Helvetica Neue;
  font-weight: 700;
`;

const H2 = styled(H1)`
  font-size: 24px;
`;

H2.defaultProps = {
  fontSize: ["16px", "20px", "20px", "24px"]
};

const H3 = styled(H1)`
  color: rgba(255, 255, 255, 0.8);
  max-width: 500px;
  font-size: 20px;
`;

H3.defaultProps = {
  fontSize: ["16px", "20px", "20px", "30px"]
};

const H4 = styled(H3)`
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
`;

H4.defaultProps = {
  fontSize: ["14px", "18px", "24px", "24px"]
};

const Phone = styled.div`
  border: 13px solid ${p => p.color || "#8a79c3"};
  border-radius: 13px;
`;

const Icon = styled.div`
  border: 2px solid ${p => p.color || "white"};
  width: 50px;
  height: 50px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
`;
const P = styled.p`
  font-family: Helvetica neue;
  font-weight: bold;
  margin: 0;
  font-size: 18px;
`;

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flexwrap: wrap;
`;

const Category = ({ color, icon, name, description }) => (
  <Box mb={4}>
    <Flex>
      <Icon color={color}>{icon}</Icon>
      <Box flex={1} ml={3}>
        <H3
          style={{
            fontSize: 18,
            margin: 0,
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)",
            marginBottom: 0
          }}
        >
          {name}
        </H3>
        <Text style={{ margin: 0, fontSize: 16 }}>{description}</Text>
      </Box>
    </Flex>
  </Box>
);

const LandingPage = () => {
  const [popup, setPopup] = useState(false);
  const [activePlace, setActivePlace] = useState(null);
  const mapRef = useRef();
  const [pins, setPins] = useState([]);
  const mapContainer = useRef();
  const [lng, setLng] = useState(-73.9712);
  const [loaded, setLoaded] = useState(false);
  const [lat, setLat] = useState(40.7831);
  const [zoom, setZoom] = useState(12);

  useEffect(() => {
    fetch("https://yogo-prod.herokuapp.com/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        query: `{
          globalPlaces {
            id
            viewer
            name
            icon
            isAddedToList
            lat
            lon
            friends {
              id
              avatarURL
            }

            street

            tags {
              name
              icon
            }
          }
        }`
      })
    })
      .then(r => r.json())
      .then(data => {
        let newPins = data.data.globalPlaces;
        setPins(newPins);
      });
  }, []);

  useEffect(() => {
    if (loaded && pins && pins.length && !!mapRef.current) {
      mapRef.current.getSource("point").setData({
        type: "FeatureCollection",
        features: pins.map(pin => ({
          type: "Feature",
          properties: {
            id: pin.id
          },
          geometry: {
            type: "Point",
            coordinates: [pin.lon, pin.lat]
          }
        }))
      });

      setActivePlace(pins.find(pin => pin.id === 1458415));
    }
  }, [loaded, pins, mapRef]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        "mapbox://styles/lunarmayor/cklfz1xs4010m19mz3n5f28mr?optimize=true",
      center: [lng, lat],
      zoom: zoom
    });
    mapRef.current = map;

    map.on("load", function() {
      map.loadImage(star, function(error, image) {
        if (error) throw error;
        map.addImage("star", image);
        map.addSource("point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        });
        map.addLayer({
          id: "points",
          type: "symbol",
          source: "point",
          layout: {
            "icon-image": "star",
            "icon-allow-overlap": true,
            "icon-size": 0.5
          }
        });
        map.on("idle", function() {
          setLoaded(true);
        });
      });
    });

    map.on("click", function(e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["points"] // replace this with the name of the layer
      });

      if (!features.length) {
        return;
      }

      var feature = features[0];
      console.log(feature.properties.id);

      setActivePlace(pins.find(pin => pin.id === feature.properties.id));
    });
    return () => map.remove();
  }, [lat, lng, pins, zoom]);

  useEffect(() => {
    setPopup(
      typeformEmbed.makePopup("https://lunarmayor.typeform.com/to/GmXnPcx8", {
        mode: "drawer_right"
      })
    );
  }, []);

  return (
    <Box>
      <Flex bg="background" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" p={3}>
          <Flex alignItems="center">
            <Box mr={2}>
              <img width={50} src={logo} />
            </Box>
            <div
              style={{
                fontFamily: "Fredoka One",
                color: "white",
                fontWeight: 400,
                fontSize: 30
              }}
            >
              Yogo
            </div>
          </Flex>
          <Flex mb={1}>
            <NavLink mb href="mailto:hyped@yogo.life">
              Contact us
            </NavLink>
          </Flex>
        </Flex>
        <Flex
          flex={1}
          p={[3, 4, 5]}
          py={[4, 5, 6]}
          pt={[1, 5, 6]}
          width={1}
          alignItems="center"
          style={{ borderBottom: "1px solid rgba(255,255,255,0.5)" }}
          flexWrap="wrap"
        >
          <MaxWidthContainer style={{ alignItems: "center", flexWrap: "wrap" }}>
            <Flex width={[1, 2 / 5]} alignItems="center" px={[3, 0]}>
              <img
                src={globe}
                style={{
                  width: "100%",
                  maxWidth: 500,
                  margin: "auto"
                }}
              />
            </Flex>
            <Box width={[1, 3 / 5]}>
              <Box ml={[0, 4, 5]}>
                <H1 mb={2}>
                  The world is large.
                  <br />
                  Explore it well.
                </H1>
                <H3 mb={4}>
                  Save and share your favorite
                  <br /> spots with friends.
                </H3>
                <BigButton href={appStoreLink}>Get the app</BigButton>
              </Box>
            </Box>
          </MaxWidthContainer>
        </Flex>
      </Flex>

      <Flex
        flex={1}
        p={[3, 4, 5]}
        py={[4, 5, 6]}
        width={1}
        alignItems="center"
        style={{ borderBottom: "1px solid rgba(255,255,255,0.5)" }}
        flexWrap="wrap"
      >
        <MaxWidthContainer style={{ alignItems: "center", flexWrap: "wrap" }}>
          <Flex width={[1, 1 / 2]} alignItems="center">
            <H4 mb={4}>
              <span style={{ color: "white" }}>Friends</span> woke up and
              realized there was no easy way to share referrals for places to go
              or things to do with their close network.
              <br />
              <br />
              People were saying "Yo, what should I do in Nashville?" and
              well traveled friends would throw together a hit list over email. With Yogo, you can capture your favorite places in the world.
            </H4>
          </Flex>
          <Box width={[1, 1 / 2]}>
            <Box ml={[0, 4, 5]} p={[3, 0]}>
              <img
                src={reach}
                style={{ width: "100%", maxWidth: 500, margin: "auto" }}
              />
            </Box>
          </Box>
        </MaxWidthContainer>
      </Flex>

      <Flex
        flex={1}
        p={[3, 4, 5]}
        py={[4, 5, 6]}
        width={1}
        alignItems="center"
        style={{ borderBottom: "1px solid rgba(255,255,255,0.5)" }}
        flexWrap="wrap"
      >
        <MaxWidthContainer style={{ alignItems: "center", flexWrap: "wrap" }}>
          <Flex width={[1, 1 / 2]} alignItems="center">
            <Box width={1} mb={[4, 0]} p={[3, 0]}>
              <img
                src={strolling}
                style={{
                  width: "100%",
                  maxWidth: 500
                }}
              />
            </Box>
          </Flex>
          <Box width={[1, 1 / 2]}>
            <Box ml={[0, 4, 5]}>
              <H4 mb={4}>
                <span style={{ color: "white" }}>Yogo is a social app </span> 
                  focused on people and places.
                <br />
                <br />
                Create a list of places you'd like to remember by dropping a pin.
                <br />
                <br />
                See what the top places your friends are discovering{" "}
                <span style={{ color: "white" }}>in your area and across the world.</span>
              </H4>{" "}
            </Box>
          </Box>
        </MaxWidthContainer>
      </Flex>
      <Flex style={{ position: "relative" }}>
        <Flex width={1} height="80vh" ref={mapContainer} />
        {activePlace && (
          <Box
            style={{
              position: "absolute",
              left: 24,
              top: 24,
              borderRadius: 10,
              zIndex: 24
            }}
          >
            <Flex p={3} bg="white" style={{ borderRadius: 10 }} width={300}>
              <Box mr={3}>
                <img src={cardStar} style={{ width: 30, height: 30 }} />
              </Box>
              <Box>
                <P>{activePlace.name}</P>
                <P
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    marginTop: 2,
                    fontSize: 12
                  }}
                >
                  {activePlace.street}
                </P>
                <Flex mt={2}>
                  {activePlace.friends.map(friend => (
                    <Box mr={1}>
                      <img
                        src={friend.avatarURL}
                        style={{ width: 25, height: 25, borderRadius: 20 }}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Flex>
          </Box>
        )}
        <Box
          style={{
            position: "absolute",
            bottom: 32,
            transform: "translate(-50%,0)",
            left: "50%",
            borderRadius: 10,
            zIndex: 20
          }}
        >
          <Box
            bg="background"
            onClick={() => {
              window.location = appStoreLink;
            }}
            p={3}
            style={{
              color: "white",
              fontFamily: "Helvetica neue",
              fontWeight: "bold",
              borderRadius: 10
            }}
          >
            Go exploring
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
export default LandingPage;
