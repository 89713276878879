import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "emotion-theming";
import theme from "./theme";
import LandingPage from "./LandingPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LandingPage />
    </ThemeProvider>
  );
}

export default App;
