import styled from "@emotion/styled";

const BigButton = styled.a`
  text-decoration: none;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 800;
  background: white;
  color: #1f28cf;
  padding: 16px 32px;
  border-radius: 10px;
  z-index: 10;
  cursor: pointer;
  display: inline-block;
`;

export default BigButton;
