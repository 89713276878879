import styled from "@emotion/styled";
import { space, typography, color } from "styled-system";

const H1 = styled.h1`
  font-family: "Helvetica Neue";
  font-weight: bold;

  line-height: 1.5;
  max-width: 640px;
  ${color}
  ${space}
  ${typography}
`;

H1.defaultProps = {
  color: "white",
  fontSize: ["24px", "32px", "32px", "48px"]
};

export default H1;
